import React, {   } from 'react';
import { Link } from "gatsby";
import Layout from '../components/Layout.js';

// import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe('pk_test_51IthjfKIa0EKLo2u8G9X70zTj7Hlpp4hV8LnnQj8EZrAp3jR4zy9zaRlYiHgN1NNU5Z5YFtFFwftnG23rkRAEeNR00G8SOHUvK');

// const StripePage = ({ location, data, path }) =>{
//   const handleClick = async (event) => {
//     // When the customer clicks on the button, redirect them to Checkout.
//     const stripe = await stripePromise;
//     const { error } = await stripe.redirectToCheckout({
//       lineItems: [{
//         price: 'price_1IthqdKIa0EKLo2uKab3YKGb', // Replace with the ID of your price
//         quantity: 1,
//       }],
//       mode: 'subscription',
//       successUrl: 'http://localhost:8000/stripesuccess?session_id={CHECKOUT_SESSION_ID}',
//       cancelUrl: 'http://localhost:8000/stripecancel/',
//       customerEmail: 'customer@example.com',
//       clientReferenceId: 'customer@example.com',
//     });
//     // If `redirectToCheckout` fails due to a browser or network
//     // error, display the localized error message to your customer
//     // using `error.message`.
//   };
//   return (
//     <>
//     <button role="link" onClick={handleClick}>
//       Checkout
//     </button>
//     <p>
//       <Link to="https://buy.stripe.com/test_cN27wm6Hv9OMcSIbII?customerEmail=customer@example.com">Go to page stripeCheckOut</Link> <br />
//     </p>
//     </>
//   );
// }

// export default StripePage

const StripePage = () => {
  return (
    <Layout>
    </Layout>
  )
}

export default StripePage